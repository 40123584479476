export const LOADED = 'btr:loaded';
export const ERROR = 'btr:error';
export const IMPRESSION = 'btr:impression';
export const NO_IMAGES = 'btr:noimages';
export const NO_URL = 'btr:nourl';
export const TAGGED = 'btr:taggedcreative';

export type BTR_COUNTERS =
    | typeof LOADED
    | typeof ERROR
    | typeof IMPRESSION
    | typeof NO_IMAGES
    | typeof NO_URL
    | typeof TAGGED;
